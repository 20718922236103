<template>
    <div id="detail">
        <el-row>
            <el-col>
                <div class="grid-content bg-purple-dark"></div>
                <el-tabs type="border-card">
                    <el-tab-pane>
                        <span slot="label" class="tab-header">Overview</span>
                        <el-row>
                            <el-col :span="24">
                                <el-card>
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ DatasetName }}</h2>
                                    </div>
                                    <div id="clu">
                                        <el-col :span="12">
                                            <div id="download">


                                                <div id="png1">
                                                    <center>
                                                        <img class="image" v-bind:src="
                                                            require('@/assets/pictures/cluster/' +
                                                                DatasetName +
                                                                '_cluster.png')
                                                        " />
                                                    </center>
                                                </div>

                                                <p id="bt" @click="dumap">Export to PDF</p>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div id="download">
                                                <div id="png2">
                                                    <center>
                                                        <img class="image" v-bind:src="
                                                            require('@/assets/pictures/assign/' +
                                                                DatasetName +
                                                                '_assign.png')
                                                        " />
                                                    </center>
                                                </div>
                                                <p id="bt" @click="dct">Export to PDF</p>
                                            </div>


                                        </el-col>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-card>
                                    <h4 style="text-align: center">Cell-type statistics</h4>
                                    <div id="pie">
                                        <img style="height:300px ;width: auto;" class="image" v-bind:src="
                                            require('@/assets/pictures/Pie/' +
                                                DatasetName +
                                                '-celltype_Pie.png')
                                        " />
                                        <img style="height:300px ;width: auto;" class="image" v-bind:src="
                                            require('@/assets/pictures/ProBar/' +
                                                DatasetName +
                                                '-celltype_ProBar.png')
                                        " />
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-card shadow="always">
                                <h4 style="text-align: center">Top differential genes for each cell type</h4>
                                <el-table ref="filterTable" :data="json_list" stripe style="width: 100%"
                                    max-height="250" v-loading="t_loading">
                                    <el-table-column prop="cluster" label="Cell Type" align="center" min-width="10%"
                                        column-key="cluster" :filters="ct_list" :filter-method="filterHandler">
                                    </el-table-column>

                                    <el-table-column prop="gene" label="Gene" align="center" min-width="15%">
                                    </el-table-column>
                                    <el-table-column prop="p_val_adj" label="Adjusted p-value" align="center" sortable
                                        min-width="15%"></el-table-column>
                                    <el-table-column prop="avg_logFC" label="log2FC" align="center" sortable
                                        min-width="30%"></el-table-column>
                                </el-table>
                            </el-card>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-header">Gene</span>
                        <el-card>
                            <el-row>
                                <el-col :span="6">
                                    Gene Exploration
                                    <el-tooltip class="item" effect="dark"
                                        content="Check the expression level of the genes you are interested in."
                                        placement="right-end">
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable
                                    remote reserve-keyword placeholder="Search gene(up to 6)"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in optionss" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-button type="info" plain @click="searchGene()">Search</el-button>
                            </el-row>
                            <el-row :gutter="20" style="margin-top: 50px">
                                <el-col :span="24">
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ DatasetName }}</h2>
                                    </div>
                                    <el-col :span="12">
                                        <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                            <img style="
													height: 100%;
													width: auto;
												" class="image2" v-bind:src="
												    require('@/assets/pictures/assign/' +
												        DatasetName +
												        '_assign.png')
												" />
                                        </div>
                                    </el-col>
                                    <el-col :span="12">


                                        <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                            <img :loading="umap_load" style="max-height: 100%" class="image3"
                                                :src="`data:image/png;base64,${img}`" />
                                        </div>




                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-row>
                            <el-card>
                                <el-row style="margin-bottom: 50px">
                                    <el-row>
                                        <el-col :span="6">Comparison</el-col>
                                    </el-row>
                                    <br />
                                    <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-button type="info" plain @click="getViolin()">Update</el-button>
                                </el-row>
                                <el-row>


                                    <div id="violin_plt">
                                        <el-col :span="24">
                                            <div id="png4" v-if="show_gene_violin">
                                                <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                    :src="`data:image/png;base64,${img_viloin}`"
                                                    style="width: 60%; display: flex; justify-content: center;" />
                                            </div>
                                            <p id="bt" @click="dv">Export to PDF</p>
                                        </el-col>



                                    </div>

                                </el-row>
                            </el-card>
                        </el-row>
                    </el-tab-pane>

                    <el-tab-pane>
                        <span slot="label" class="tab-header">GSEA</span>
                        <div v-if="warn_GSEA">
                            <span style="color: rgb(26, 96, 67) ;font-size: 30px;">No GSSEA Data</span>
                        </div>
                        <el-card v-if="show_GSEA">
                            <el-tabs type="border-card">
                                <el-tab-pane>
                                    <span slot="label" class="tab-header">KEGG</span>
                                    <div id="gsea_plt">
                                        <span
                                            style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                            UP-REGULATED
                                            KEGG PATHWAYS
                                        </span>
                                        <div id="download">
                                            <div id="png5">


                                                <el-image v-bind:src="
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_UP_heatmap.png')
                                                " :preview-src-list="[
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_DOWN_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_DOWN_heatmap.png'),
                                                ]"></el-image>
                                            </div>
                                            <p id="bt" @click="dk1">Export to PDF</p>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div id="gsea_plt">
                                        <span
                                            style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                            DOWN-REGULATED
                                            KEGG PATHWAYS
                                        </span>
                                        <div id="download">
                                            <div id="png6">


                                                <el-image class="image" v-bind:src="
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_DOWN_heatmap.png')
                                                " :preview-src-list="[
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_DOWN_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_DOWN_heatmap.png'),
                                                ]"></el-image>
                                            </div>
                                            <p id="bt" @click="dk2">Export to PDF</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane>
                                    <span slot="label" class="tab-header">Hallmark</span>
                                    <div id="gsea_plt">
                                        <span
                                            style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                            UP-REGULATED
                                            HALLMARK PATHWAYS
                                        </span>
                                        <div id="download">



                                            <div id="png7">


                                                <el-image class="image" v-bind:src="
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_UP_heatmap.png')
                                                " :preview-src-list="[
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_DOWN_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_DOWN_heatmap.png'),
                                                ]"></el-image>
                                            </div>
                                            <p id="bt" @click="dh1">Export to PDF</p>
                                        </div>
                                    </div>
                                    <br>
                                    <br>

                                    <div id="gsea_plt">
                                        <span
                                            style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                            DOWN-REGULATED
                                            HALLMARK PATHWAYS
                                        </span>
                                        <div id="download">


                                            <div id="png8">


                                                <el-image class="image" v-bind:src="
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_DOWN_heatmap.png')
                                                " :preview-src-list="[
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_DOWN_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_hallmark_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_UP_heatmap.png'),
                                                    require('@/assets/pictures/GSEA_plot/' +
                                                        DatasetName +
                                                        '_kegg_DOWN_heatmap.png'),
                                                ]"></el-image>
                                            </div>
                                            <p id="bt" @click="dh2">Export to PDF</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </el-card>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-header">Cell-Cell Interaction</span>
                        <el-row :span="24">
                            <el-card>
                                <div id="cc">
                                    <el-row type="flex" align="middle">
                                        <el-col :span="12">
                                            <div id="download">
                                                <div id="png9">
                                                    <img class="image" v-bind:src="
                                                        require('@/assets/pictures/CCI_PLT/' +
                                                            DatasetName +
                                                            '_Interaction.png')
                                                    " />
                                                </div>
                                                <!-- <p id="bt" @click="dccih">Export to PDF</p> -->
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltUrl">
                                                <el-option v-for="item in Cluster" :key="item" :label="item"
                                                    :value="item">
                                                </el-option>
                                            </el-select>
                                            <div v-if="tmp_show">
                                                <div id="download">
                                                    <div id="png10">
                                                        <img class="image" v-bind:src="
                                                            require('@/assets/pictures/CCI_PLT/' +
                                                                DatasetName +
                                                                '_C' +
                                                                tmp +
                                                                '_circle.png')
                                                        " />
                                                    </div>
                                                    <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                                </div>
                                            </div>
                                            <div v-if="plt_show">
                                                <div id="download">
                                                    <div id="png10">
                                                        <img class="image" v-bind:src="
                                                            require('@/assets/pictures/CCI_PLT/' +
                                                                plt_url +
                                                                '_circle.png')
                                                        " />
                                                    </div>
                                                    <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                                <br>
                                <div id="sxy">
                                    <div>
                                        <p id="bt" @click="dccih">Export to PDF</p>
                                    </div>
                                    <div>
                                        <p id="bt" @click="dccic">Export to PDF</p>
                                    </div>
                                </div>
                            </el-card>
                            <div v-if="plt_show" id="warning">
                                <br />
                                <el-col :span="12">
                                    <div id="download">
                                        <div id="png11">
                                            <img class="image" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/' +
                                                    DatasetName +
                                                    '_' +
                                                    clu +
                                                    '_in.png')
                                            " />
                                        </div>
                                        <p id="bt" @click="dcciin">Export to PDF</p>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div id="download">
                                        <div id="png12">
                                            <img class="image" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/' +
                                                    DatasetName +
                                                    '_' +
                                                    clu +
                                                    '_out.png')
                                            " />
                                        </div>
                                        <p id="bt" @click="dcciout">Export to PDF</p>
                                    </div>
                                </el-col>

                            </div>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-header">TF</span>
                        <el-card>
                            <div v-if="warn_TF">
                                <span style="color: rgb(26, 96, 67) ;font-size: 30px;">No TF Data</span>
                            </div>
                            <el-row align="middle">
                                <el-col :span="12">
                                    <div id="download">
                                        <div id="png13">
                                            <img class="image"
                                                style="width: 100%; display: flex; justify-content: center;" v-bind:src="require('../assets/pictures/TFheatmap/' +
                                                    DatasetName + '_TFheatmap.png')
                                                " />
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div id="tf_option">
                                        <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltDot">
                                            <el-option v-for="item in Cluster" :key="item" :label="item" :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>

                                    <div v-if="tmp_show1" class="tf_right">
                                        <div id="download">
                                            <div id="png14">
                                                <img class="image" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        DatasetName +
                                                        '/C' +
                                                        tmp +
                                                        '_TF.png')
                                                " />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="plt_show1" class="tf_right">
                                        <div id="download">
                                            <div id="png14">
                                                <img class="image" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        DatasetName +
                                                        PltDot +
                                                        '_TF.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <br>
                            <div id="sxy">
                                <div>
                                    <p id="bt" @click="dtfh">Export to PDF</p>
                                </div>
                                <div>
                                    <p id="bt" @click="dtfc">Export to PDF</p>
                                </div>
                            </div>
                        </el-card>
                    </el-tab-pane>

                    <el-tab-pane>
                        <span slot="label" class="tab-header">Download</span>
                        <el-row :span="24">
                            <el-card id="download">
                                <el-button type="primary" round @click="downloadh5()">Expression Matrix</el-button>
                                <el-button type="primary" round @click="downloadmeta()">Meta File</el-button>
                                <el-button type="primary" round @click="downloadcci()">Cell-Cell Interaction</el-button>
                                <el-button type="primary" round @click="downloadtf()">TF Prediction</el-button>
                            </el-card>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>


<script lang="ts">
import { searchGene } from "@/api/getGene.ts";
import { getGene } from "@/api/getGene.ts";
import { getViolin } from "@/api/getGene.ts";
import { BIconLayoutTextWindowReverse } from "bootstrap-vue";
import TF_CT from "@/assets/TF_CT.json"; // 引用
import { Loading } from "element-ui";
import CCI_Cluster from "@/assets/CCI_Cluster.json";
import html2pdf from "html2pdf.js";
export default {
    props: ["img"],
    data() {
        return {
            scrollContainer: HTMLElement,
            DatasetName: this.$route.params.DatasetName,
            json_list: [],
            ct_list: [],
            ct_tmp: [],
            data_list: [],
            currentPage: 1,
            pageSize: 10,
            inputGene: [],
            available_genes: "",
            state2: "",
            links: [],
            show_gene_umap: false,
            show_gene_violin: false,
            value: "",
            plt_url: "",
            tmp_show: true,
            plt_show: false,
            tmp_show1: true,
            plt_show1: false,
            gene: "",
            optionss: [],
            list: [],
            loading: false,
            violin_load: false,
            t_loading: true,
            warning: false,
            clu: "",
            tmp: "",
            umap_load: false,
            show_TF: true,
            warn_TF: false,
            show_GSEA: true,
            warn_GSEA: false,
            no_TF: [
                "HU_0107_Bladder_GSE134355",
                "HU_0150_Esophagus_GSE134355",
                "HU_0158_Gall-Bladder_GSE134355",
                "HU_0159_Gall-Bladder_GSE134355",
                "HU_0199_Kidney_GSE145927",
                "HU_0205_Liver_GSE136103",
                "HU_0206_Liver_GSE134355",
                "HU_0207_Liver_GSE134355",
                "HU_0208_Liver_GSE134355",
                "HU_0217_Lung_GSE134355",
                "HU_0223_Muscle_GSE134355",
                "HU_0225_Nose_GSE139522",
                "HU_0230_Pancreas_EBI",
                "HU_0238_Placenta_GSE134355",
                "HU_0239_Placenta_GSE134355",
                "HU_0245_Spleen_GSE134355",
                "HU_0246_Stomach_GSE134355",
                "HU_0270_Adipose_GSE136229"
            ],
            no_GSEA: ["HU_0112_Bone-Marrow_GSE117498", "HU_0142_Breast_GSE138536"],
            tf_true: true,

            umap_path: "",
            options: [
                {
                    value: "CellType",
                    label: "CellType"
                },
                {
                    value: "Cluster",
                    label: "Cluster"
                }
            ],
            compareType: "CellType"
        };
    },

    watch: {
        $route() {
        }
    },
    created() {
        if (this.no_TF.indexOf(this.DatasetName) > -1) {
            this.show_TF = true;
            this.warn_TF = false;
        }
        if (this.no_GSEA.indexOf(this.DatasetName) > -1) {
            this.show_GSEA = true;
            this.warn_GSEA = false;
        }

        this.getDataList();
        this.DatasetName = this.$route.params.DatasetName;
        this.CCI_Cluster = CCI_Cluster;
        this.Cluster = this.CCI_Cluster[this.DatasetName].sort((a, b) => a - b);
        this.tmp = this.Cluster[0];
        this.TF_CT = TF_CT;
        this.CT = this.TF_CT[this.DatasetName];
        this.getJsonFile(this.DatasetName);
        if (typeof this.CT == "undefined") {
            this.warning = true;
        }
        if (this.tf_list.indexOf(this.DatasetName) > -1) {
            this.tf_true = true;
        } else {
            this.tf_tmp = true;
        }
    },

    methods: {
        dumap() {
            html2pdf(document.getElementById("png1"), {
                margin: 1,
                filename: "UMAP.pdf",
            });
        },
        dct() {
            html2pdf(document.getElementById("png2"), {
                margin: 1,
                filename: "CellType.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dfp() {
            html2pdf(document.getElementById("png3"), {
                margin: 1,
                filename: "Gene.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dv() {
            html2pdf(document.getElementById("png4"), {
                margin: 1,
                filename: "Gene_Violin.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dk1() {
            html2pdf(document.getElementById("png5"), {
                margin: 1,
                filename: "KEGG_UP.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dk2() {
            html2pdf(document.getElementById("png6"), {
                margin: 1,
                filename: "KEGG_DOWN.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dh1() {
            html2pdf(document.getElementById("png7"), {
                margin: 1,
                filename: "Hallmark_UP.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dh2() {
            html2pdf(document.getElementById("png8"), {
                margin: 1,
                filename: "Hallmark_Dwon.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dccih() {
            html2pdf(document.getElementById("png9"), {
                margin: 1,
                filename: "CCI_Heatmap.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dccic() {
            html2pdf(document.getElementById("png10"), {
                margin: 1,
                filename: "CCI_Circle.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dcciin() {
            html2pdf(document.getElementById("png11"), {
                margin: 1,
                filename: "CCI_in.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dcciout() {
            html2pdf(document.getElementById("png12"), {
                margin: 1,
                filename: "CCI_out.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dtfh() {
            html2pdf(document.getElementById("png13"), {
                margin: 1,
                filename: "TF_heat.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dtfc() {
            html2pdf(document.getElementById("png14"), {
                margin: 1,
                filename: "TF_Rank.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        downloadh5() {
            let link = document.createElement("a");
            link.setAttribute("download", "");
            link.href =
                "https://biostorage.s3.ap-northeast-2.amazonaws.com/HUSCH/HUSCH_data/" +
                this.DatasetName +
                "/" +
                this.DatasetName +
                "_gene_count.h5"; // resource location
            link.click();
        },
        downloadmeta() {
            let link = document.createElement("a");
            link.setAttribute("download", "");
            link.href =
                "https://biostorage.s3.ap-northeast-2.amazonaws.com/HUSCH/HUSCH_data/" +
                this.DatasetName +
                "/" +
                this.DatasetName +
                "_meta.txt"; // resource location
            link.click();
        },
        downloadcci() {
            let link = document.createElement("a");
            link.setAttribute("download", "");
            link.href =
                "https://biostorage.s3.ap-northeast-2.amazonaws.com/HUSCH/downstream/" +
                this.DatasetName +
                "_CCI_LR.tsv"; // resource location
            link.click();
        },
        downloadtf() {
            let link = document.createElement("a");
            link.setAttribute("download", "");
            link.href =
                "https://biostorage.s3.ap-northeast-2.amazonaws.com/HUSCH/downstream/" +
                this.DatasetName +
                "_TFtop5.txt"; // resource location
            link.click();
        },
        alert() {
            this.$alert(
                "During the test phase, the data is only available for download from the internal network.",
                "Warning",
                {
                    confirmButtonText: "OK",
                    callback: action => {
                        this.$message({
                            type: "info",
                            message: `action: ${action}`
                        });
                    }
                }
            );
        },
        changeActive($event) {
            $event.currentTarget.className = "animate__animated animate__headShake";
        },
        removeActive($event) {
            $event.currentTarget.className = "";
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loading = false;
                setTimeout(() => {
                    this.loading = false;
                    this.optionss = this.list.filter(item => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.optionss = this.list.slice(0, 8);
            }
            console.log(this.optionss)
        },
        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] == value;
        },
        async getJsonFile(dataset) {
            const response = await this.axios({
                method: "post",
                url: "get_json/",
                data: {
                    dataset: this.DatasetName
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.json_list = response["data"][0];
            this.ct_tmp = response["data"][1];

            for (var index = 0; index < Object.values(this.ct_tmp).length; index++) {
                var tmp = { text: this.ct_tmp[index], value: this.ct_tmp[index] };
                this.ct_list.push(tmp);
            }
            this.t_loading = false;
        },

        async getDataList() {
            const { data: res } = await this.axios.get(
                "/" + this.DatasetName + ".json"
            );
            this.json_list = res;
        },
        getPltUrl() {
            this.plt_url = this.DatasetName + "_C" + this.clu;
            this.tmp_show = false;
            this.plt_show = true;
        },
        getPltDot() {
            this.PltDot = "/C" + this.clu;
            this.tmp_show1 = false;
            this.plt_show1 = true;
        },
        async getViolin(gene, dataset, compare) {
            try {
                let loadingInstance = Loading.service({
                    target: document.getElementById("violin_plt"),
                    fullscreen: true
                });
                this.show_gene_violin = false;
                this.inputGene = this.inputGene.filter(item => {
                    return item.toUpperCase();
                });
                const response = await this.axios({
                    method: "post",
                    url: "violin_gene/",
                    data: {
                        gene: this.inputGene,
                        dataset: this.DatasetName,
                        compare: this.compareType
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                this.img_viloin = response["data"];
                this.show_gene_violin = true;
                loadingInstance.close();
            } catch (err) {
                this.$alert("Please try again.", "Gene", {
                    confirmButtonText: "OK",
                    callback: action => {
                        this.$message({
                            type: "info",
                            message: `action: ${action}`
                        });
                    }
                });
            }
        },

        async searchGene(gene, dataset) {
            this.umap_load = true;
            this.inputGene = this.inputGene.filter(item => {
                return item.toUpperCase();
            });
            const response = await this.axios({
                method: "post",
                url: "detail_gene/",
                data: {
                    gene: this.inputGene,
                    dataset: this.DatasetName
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.img = response["data"];
            this.show_gene_umap = true;
            this.umap_load = true;
        },

        async getGene(dataset) {
            const response = await this.axios({
                method: "post",
                url: "get_gene/",
                data: {
                    dataset: this.DatasetName
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.available_genes = response["data"];
            this.list = this.available_genes.map(item => {
                return { value: `${item}`, label: `${item}` };
            });
            return this.available_genes;
        }
    },

    mounted() {
        this.available_genes = this.getGene();
        this.optionss = this.list.slice(0, 8);
        console.log(this.optionss)
    }
};
</script>


<style lang="scss" scoped>
@import "../styles/detail.scss";

#clu {
    display: flex;
    flex-direction: row;
}

#pie {
    display: flex;
    flex-direction: row;
    height: 40%;
    justify-content: space-around;
}

#cc {
    display: flex;
    flex-direction: row;
}

#download {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#pic_gsea {
    display: flex;
    flex-direction: row;

    #pie {
        width: 50%;
    }
}

template {
    font-size: 40px;
}

.el-collapse-item__header {
    font-size: 30px !important;
}

.el-collapse-item>>>.el-collapse-item__header {
    font-size: 30px !important;
    color: rgb(39, 196, 145);
}

.el-card ::v-deep .el-card_header {
    font-size: 40px;
    background-color: rgb(39, 196, 145);
    color: white;
}

.analysis_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(39, 196, 145);
    margin-bottom: 40px;
}

.scrollable {
    overflow-x: auto;
    overflow-y: hidden;
    height: 250px;
    white-space: nowrap;

    img {
        width: auto;
        height: 100%;
        margin-right: 10px;
    }
}

.el-select__inner {
    width: 190px;
    height: 500px;
}

#warning {
    margin: 30px;
}

#imggg {
    display: flex;
    flex-direction: row;
}

// #download {
//     display: flex;
//     flex-direction: column;
// }

#end {
    display: row;
    flex-direction: row;
    align-content: flex-end;
}

#sxy {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#png14 {
    margin-left: 5%;
    margin-right: 5%;
}

#bt {
    font-size: 10px;
    color: rgb(26, 96, 67);
    margin: auto;
}

#tf_option {
    // margin-top: 5px;
    margin-bottom: 10%;
}
.tf_right{
    // float: left;
    margin-bottom: 5px;
    // vertical-align: middle;
}
</style>